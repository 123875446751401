import { ApiBase } from "./api-base";
import {
	GQL_AI_ACTION_SHAPE,
	GQL_AUTH_TOKEN,
	GQL_AUTH_USER_INFO_TOKEN,
	GQL_CHAT_MESSAGE_SHAPE,
	GQL_COPILOT_SHAPE,
	GQL_IDEA_CLUSTER_SHAPE,
	GQL_IDEA_SHAPE,
	GQL_LINK_SHAPE,
	GQL_MEDIA_SHAPE,
	GQL_PLAN_SHAPE,
	GQL_POLL_SHAPE,
	GQL_ROBOT_SHAPE,
	GQL_ROOM_ACCESS_SHAPE,
	GQL_ROOM_SHAPE,
	GQL_ROOM_SNAPSHOT,
	GQL_SESSION_SHAPE,
	GQL_SUBSCRIPTION_SHAPE,
	GQL_UNFURLED_URL_SHAPE,
	GQL_USER_SHAPE,
	GQL_WORKSPACE_ACCESS_SHAPE,
	GQL_WORKSPACE_SHAPE
} from "./gql/gql-shape";
import {
	AIAction,
	AIActionContext,
	AISensitivityKind,
	AuthCredentials,
	BillingPortalIntent,
	BuiltInAIActionKind,
	builtInAIActionKindToId,
	BulkUpdateEntry,
	ChatMessage,
	Copilot,
	FriendlyId,
	Id,
	Idea,
	IdeaCluster,
	isId,
	isModel,
	Link,
	MAX_FILE_SIZE_BYTES,
	Media,
	MediaDAO,
	ModelData,
	Poll,
	PollRankedIdeasFilter,
	QueryOptions,
	Ref,
	Robot,
	Room,
	RoomAccess,
	RoomSnapshot,
	RoomTimer,
	StabilityAiStylePreset,
	SymbolVotingResults,
	takeId,
	User,
	VoteEvaluationCriteria,
	Workspace,
	WorkspaceAccess
} from "shared";
import { logout } from "../state/session/session-ac";
import { GQLShape } from "./gql/gql-util";
import { API_BASE_URL } from "../config";
import { workspaceStore } from "../state/workspace/workspace-store";

export interface APIRequestOptions<T> {
	abortSignal?: AbortSignal;
	shape?: GQLShape<T>;
}

export class Api extends ApiBase {
	protected async logout(): Promise<void> {
		await logout();
	}

	session = {
		login: (credentials: AuthCredentials) => this.mutate("login", { credentials }, GQL_SESSION_SHAPE),
		logout: () => this.mutate("logout", {}, undefined, { skipLogoutOnUnauthorized: true }),
		signup: (credentials: AuthCredentials, data?: Partial<User>) =>
			this.mutate("signup", { data, credentials }, GQL_SESSION_SHAPE),
		refreshToken: (refreshToken: string) => this.mutate("refreshToken", { refreshToken }, GQL_AUTH_TOKEN),
		authUserInfo: (credentials: AuthCredentials) =>
			this.mutate("authUserInfo", { credentials }, GQL_AUTH_USER_INFO_TOKEN),
		setCredentials: (credentials: AuthCredentials) => this.mutate("setCredentials", { credentials }, undefined)
	};

	room = {
		list: (options?: QueryOptions<Room>) => this.query("rooms", { options }, GQL_ROOM_SHAPE),
		join: (id: Ref<Room>, accessCode: string) =>
			this.mutate("joinRoom", { id: takeId(id), accessCode }, GQL_ROOM_SHAPE),
		leave: (id: Ref<Room>) => this.mutate("leaveRoom", { id: takeId(id) }, undefined),
		get: ({ id, accessCode, slug }: { id?: Ref<Room>; accessCode?: string; slug?: string }) =>
			this.query("room", { id: takeId(id), accessCode, slug }, GQL_ROOM_SHAPE),
		create: (workspace: Ref<Workspace>, data: Partial<Room>) =>
			this.mutate("createRoom", { workspaceId: takeId(workspace), data }, GQL_ROOM_SHAPE),
		update: (id: Ref<Room>, data: Partial<Room>) => this.mutate("updateRoom", { id: takeId(id), data }, GQL_ROOM_SHAPE),
		remove: (id: Ref<Room>) => this.mutate("removeRoom", { id: takeId(id) }, undefined),
		removeUserFromRoom: (roomId: Ref<Room>, userId: Ref<User>) =>
			this.mutate("removeUserFromRoom", { roomId: takeId(roomId), userId: takeId(userId) }, undefined),
		updateTimer: (roomId: Ref<Room>, timer: RoomTimer | null) =>
			this.mutate("updateTimer", { roomId: takeId(roomId), timer }, undefined),
		forceFollow: (roomId: Ref<Room>, userId?: Id<User>) =>
			this.mutate("forceFollow", { roomId: takeId(roomId), userId }, undefined)
	};

	user = {
		list: (roomId: Ref<Room>, options?: QueryOptions<User>, listAll?: boolean) =>
			this.query("users", { roomId: takeId(roomId), listAll, options }, GQL_USER_SHAPE),
		listInvitableUsers: (roomId: Ref<Room>, options?: QueryOptions<User>) =>
			this.query("invitableUsers", { roomId: takeId(roomId), options }, GQL_USER_SHAPE),
		listRelevantUsers: (roomId: Ref<Room>, options?: QueryOptions<User>) =>
			this.query("relevantUsers", { roomId: takeId(roomId), options }, GQL_USER_SHAPE),
		get: (id: Ref<User>) => this.query("user", { id: takeId(id) }, GQL_USER_SHAPE),
		me: () => this.query("me", {}, GQL_USER_SHAPE),
		create: (data: Partial<User>) => this.mutate("createUser", { data }, GQL_USER_SHAPE),
		update: (id: Ref<User>, data: Partial<User>) => this.mutate("updateUser", { id: takeId(id), data }, GQL_USER_SHAPE),
		remove: (id: Ref<User>) => this.mutate("removeUser", { id: takeId(id) }, undefined)
	};

	robot = {
		list: (options?: QueryOptions<Robot>) => this.query("robots", { options }, GQL_ROBOT_SHAPE),
		get: (id: Ref<Robot>) => this.query("robot", { id: takeId(id) }, GQL_ROBOT_SHAPE),
		create: (data: Partial<Robot>) => this.mutate("createRobot", { data }, GQL_ROBOT_SHAPE),
		update: (id: Ref<Robot>, data: Partial<Robot>) =>
			this.mutate("updateRobot", { id: takeId(id), data }, GQL_ROBOT_SHAPE),
		remove: (id: Ref<Robot>) => this.mutate("removeRobot", { id: takeId(id) }, undefined)
	};

	roomAccess = {
		list: (room: Ref<Room>, options?: QueryOptions<RoomAccess>) =>
			this.query("roomAccesses", { roomId: takeId(room), options }, GQL_ROOM_ACCESS_SHAPE),
		get: (id: Ref<RoomAccess>) => this.query("roomAccess", { id: takeId(id) }, GQL_ROOM_ACCESS_SHAPE),
		create: (roomId: Ref<Room>, data: Partial<RoomAccess>) =>
			this.mutate("createRoomAccess", { roomId: takeId(roomId), data }, GQL_ROOM_ACCESS_SHAPE),
		update: (id: Ref<RoomAccess>, data: Partial<RoomAccess>) =>
			this.mutate("updateRoomAccess", { id: takeId(id), data }, GQL_ROOM_ACCESS_SHAPE),
		remove: (id: Ref<RoomAccess>) => this.mutate("removeRoomAccess", { id: takeId(id) }, undefined)
	};

	clearbit = {
		suggestions: (query: string) => this.query("clearbitSuggestions", { query }, true),
		domainLookup: (domain: string) => this.query("clearbitDomainLookup", { domain }, true)
	};

	idea = {
		listVoteDistributionInPoll: (poll: Ref<Poll>, pollFilter?: PollRankedIdeasFilter, options?: QueryOptions<Idea>) =>
			this.query("voteDistributionInPoll", { pollId: takeId(poll), pollFilter, options }, true),
		listIdeasForComparison: (pollId: Ref<Poll>, options?: QueryOptions<Idea>) =>
			this.query("ideasForComparison", { pollId: takeId(pollId), options }, GQL_IDEA_SHAPE),
		listInPoll: (poll: Ref<Poll>, pollFilter?: PollRankedIdeasFilter, options?: QueryOptions<Idea>) =>
			this.query("ideasInPoll", { pollId: takeId(poll), pollFilter, options }, GQL_IDEA_SHAPE),
		list: (options?: QueryOptions<Idea>, { abortSignal, shape }: APIRequestOptions<Idea> = {}) =>
			this.query("ideas", { options }, shape ?? GQL_IDEA_SHAPE, { abortSignal }),
		get: (id: Ref<Idea> | FriendlyId) =>
			this.query("idea", isId(id) || isModel(id) ? { id: takeId(id) } : { friendlyId: id }, GQL_IDEA_SHAPE),
		bulkUpdate: (entries: BulkUpdateEntry<Idea>[]) => this.mutate("bulkUpdateIdeas", { entries }, GQL_IDEA_SHAPE),
		bulkRemove: (ideaIds: Id<Idea>[], { includeChildren }: { includeChildren?: boolean } = {}) =>
			this.mutate("bulkRemoveIdeas", { ideaIds, includeChildren }, true),
		create: (roomId: Ref<Room>, data: ModelData<Idea>) =>
			this.mutate("createIdea", { roomId: takeId(roomId), data }, GQL_IDEA_SHAPE),
		update: (id: Ref<Idea>, data: ModelData<Idea>) =>
			this.mutate("updateIdea", { id: takeId(id), data }, GQL_IDEA_SHAPE),
		remove: (id: Ref<Idea>) => this.mutate("removeIdea", { id: takeId(id) }, undefined),
		clusterIdeas: (roomId: Ref<Room>, ideaClusters: IdeaCluster[]) =>
			this.mutate("clusterIdeas", { roomId: takeId(roomId), ideaClusters }, undefined),
		reactToIdea: (ideaId: Ref<Idea>, reaction: string, count?: number) =>
			this.mutate("reactToIdea", { ideaId: takeId(ideaId), reaction, count }, undefined),
		lockIdea: (ideaId: Ref<Idea>, locked: boolean) =>
			this.mutate("lockIdea", { ideaId: takeId(ideaId), locked }, undefined)
	};

	link = {
		// list: (room: Ref<Room>, options?: QueryOptions<Link>) =>
		// 	this.query("invitations", { roomId: takeId(room), options }, GQL_INVITATION_SHAPE),
		get: (id: Ref<Link>) => this.query("link", { id: takeId(id) }, GQL_LINK_SHAPE),
		// create: (room: Ref<Room>, data: Partial<Link>) =>
		// 	this.mutate("createInvitation", { roomId: takeId(room), data }, GQL_INVITATION_SHAPE),
		update: (id: Ref<Link>, data: Partial<Link>) => this.mutate("updateLink", { id: takeId(id), data }, GQL_LINK_SHAPE)
		// remove: (id: Ref<Link>) => this.mutate("removeInvitation", { id: takeId(id) }, undefined)
	};

	copilot = {
		list: (room: Ref<Room>, options?: QueryOptions<Copilot>) =>
			this.query("copilots", { roomId: takeId(room), options }, GQL_COPILOT_SHAPE),
		get: (id: Ref<Copilot>) => this.query("copilot", { id: takeId(id) }, GQL_COPILOT_SHAPE),
		create: (room: Ref<Room>, data: Partial<Copilot>) =>
			this.mutate("createCopilot", { roomId: takeId(room), data }, GQL_COPILOT_SHAPE),
		update: (id: Ref<Copilot>, data: Partial<Copilot>) =>
			this.mutate("updateCopilot", { id: takeId(id), data }, GQL_COPILOT_SHAPE),
		remove: (id: Ref<Copilot>) => this.mutate("removeCopilot", { id: takeId(id) }, undefined)
	};

	chatMessage = {
		list: (copilot: Ref<Copilot>, options?: QueryOptions<ChatMessage>) =>
			this.query("chatMessages", { copilotId: takeId(copilot), options }, GQL_CHAT_MESSAGE_SHAPE),
		get: (id: Ref<ChatMessage>) => this.query("chatMessage", { id: takeId(id) }, GQL_CHAT_MESSAGE_SHAPE),
		create: (copilot: Ref<Copilot>, data: Partial<ChatMessage>) =>
			this.mutate("createChatMessage", { copilotId: takeId(copilot), data }, GQL_CHAT_MESSAGE_SHAPE),
		update: (id: Ref<ChatMessage>, data: Partial<ChatMessage>) =>
			this.mutate("updateChatMessage", { id: takeId(id), data }, GQL_CHAT_MESSAGE_SHAPE),
		remove: (id: Ref<ChatMessage>) => this.mutate("removeChatMessage", { id: takeId(id) }, undefined),
		clear: (copilot: Ref<Copilot>) => this.mutate("clearChatMessages", { copilotId: takeId(copilot) }, undefined)
	};

	media = {
		create: (fileName: string, data: Partial<Media>) => this.mutate("createMedia", { fileName, data }, GQL_MEDIA_SHAPE),
		createFromUrl: (url: string, data: Partial<Media>) =>
			this.mutate("createMediaFromUrl", { url, data }, GQL_MEDIA_SHAPE),
		// list: (options?: QueryOptions<Media>) => this.query("medias", { options }, GQL_MEDIA_SHAPE),
		// get: (id: Id<Media>) => this.query("media", { id }, GQL_MEDIA_SHAPE),
		remove: (id: Ref<Media>) => this.mutate("removeMedia", { id: takeId(id) }, undefined),
		update: (id: Ref<Media>, data: Partial<Media>) =>
			this.mutate("updateMedia", { id: takeId(id), data }, GQL_MEDIA_SHAPE),
		uploadFileToSignedUrl: (signedUrl: string, file: File) =>
			fetch(signedUrl, {
				method: "PUT",
				body: file,
				headers: {
					"x-goog-content-length-range": `0,${MAX_FILE_SIZE_BYTES}`
				}
			}),
		generate: (
			prompt: string,
			initImage?: Ref<Media>,
			style?: StabilityAiStylePreset,
			sensitivity?: AISensitivityKind
		) => this.mutate("generateImage", { prompt, initImage: takeId(initImage), style, sensitivity }, GQL_MEDIA_SHAPE),
		detect: (media: Ref<Media>) => this.mutate("detectInImage", { mediaId: takeId(media) }, true as any),
		extractScreenshotFromUrl: (url: string) => this.mutate("extractScreenshotFromUrl", { url }, GQL_MEDIA_SHAPE)
	};

	workspace = {
		join: (id: Ref<Workspace>, accessCode: string) =>
			this.mutate("joinWorkspace", { id: takeId(id), accessCode }, GQL_WORKSPACE_SHAPE),
		list: (options?: QueryOptions<Workspace>) => this.query("workspaces", { options }, GQL_WORKSPACE_SHAPE),
		get: ({ id, accessCode }: { id?: Ref<Workspace>; accessCode?: string }) =>
			this.query("workspace", { id: takeId(id), accessCode }, GQL_WORKSPACE_SHAPE),
		create: (data: Partial<Workspace>) => this.mutate("createWorkspace", { data }, GQL_WORKSPACE_SHAPE),
		update: (id: Ref<Workspace>, data: Partial<Workspace>) =>
			this.mutate("updateWorkspace", { id: takeId(id), data }, GQL_WORKSPACE_SHAPE),
		remove: (id: Ref<Workspace>) => this.mutate("removeWorkspace", { id: takeId(id) }, undefined),
		billingPortalUrl: (workspaceId: Ref<Workspace>, intent?: BillingPortalIntent) =>
			this.query("billingPortalUrl", { workspaceId: takeId(workspaceId), intent }, true),
		subscription: (workspaceId: Ref<Workspace>) =>
			this.query("subscription", { workspaceId: takeId(workspaceId) }, GQL_SUBSCRIPTION_SHAPE),
		requestUpgrade: (workspaceId: Ref<Workspace>) =>
			this.mutate("requestUpgrade", { workspaceId: takeId(workspaceId) }, undefined)
	};

	plan = {
		listPlans: (workspaceId?: Ref<Workspace>) =>
			this.query("listPlans", { workspaceId: takeId(workspaceId) }, GQL_PLAN_SHAPE)
	};

	workspaceAccess = {
		list: (workspace: Ref<Workspace>, options?: QueryOptions<WorkspaceAccess>) =>
			this.query("workspaceAccesses", { workspaceId: takeId(workspace), options }, GQL_WORKSPACE_ACCESS_SHAPE),
		get: (id: Ref<WorkspaceAccess>) => this.query("workspaceAccess", { id: takeId(id) }, GQL_WORKSPACE_ACCESS_SHAPE),
		create: (workspace: Ref<Workspace>, data: Partial<WorkspaceAccess>) =>
			this.mutate("createWorkspaceAccess", { workspaceId: takeId(workspace), data }, GQL_WORKSPACE_ACCESS_SHAPE),
		update: (id: Ref<WorkspaceAccess>, data: Partial<WorkspaceAccess>) =>
			this.mutate("updateWorkspaceAccess", { id: takeId(id), data }, GQL_WORKSPACE_ACCESS_SHAPE),
		remove: (id: Ref<WorkspaceAccess>) => this.mutate("removeWorkspaceAccess", { id: takeId(id) }, undefined)
	};

	poll = {
		list: (room: Ref<Room>, options?: QueryOptions<Poll>) =>
			this.query("polls", { roomId: takeId(room), options }, GQL_POLL_SHAPE),
		get: (id: Ref<Poll>) => this.query("poll", { id: takeId(id) }, GQL_POLL_SHAPE),
		create: (room: Ref<Room>, data: Partial<Poll>) =>
			this.mutate("createPoll", { roomId: takeId(room), data }, GQL_POLL_SHAPE),
		update: (id: Ref<Poll>, data: Partial<Poll>) => this.mutate("updatePoll", { id: takeId(id), data }, GQL_POLL_SHAPE),
		remove: (id: Ref<Poll>) => this.mutate("removePoll", { id: takeId(id) }, undefined),
		finishPoll: (id: Ref<Poll>) => this.mutate("finishPoll", { id: takeId(id) }, undefined),
		startPoll: (id: Ref<Poll>) => this.mutate("startPoll", { id: takeId(id) }, undefined)
	};

	roomSnapshot = {
		list: (room: Ref<Room>, options?: QueryOptions<RoomSnapshot>) =>
			this.query("roomSnapshots", { roomId: takeId(room), options }, GQL_ROOM_SNAPSHOT),
		listIdeas: (roomSnapshot: Ref<RoomSnapshot>, options?: QueryOptions<Idea>) =>
			this.query("roomSnapshotIdeas", { roomSnapshotId: takeId(roomSnapshot), options }, GQL_IDEA_SHAPE, {
				skipAbsorbModel: true
			}),
		restore: (roomSnapshot: Ref<RoomSnapshot>) =>
			this.mutate("roomSnapshotRestore", { roomSnapshotId: takeId(roomSnapshot) }, undefined)
	};

	vote = {
		commitComparisonVote: (poll: Ref<Poll>, options: { winner: Ref<Idea>; loser: Ref<Idea> }) =>
			this.mutate(
				"commitComparisonVote",
				{ pollId: takeId(poll), winnerId: takeId(options.winner), loserId: takeId(options.loser) },
				undefined
			),
		commitDotVote: (poll: Ref<Poll>, results: SymbolVotingResults) =>
			this.mutate("commitDotVote", { pollId: takeId(poll), results }, undefined),
		commitCriteriaVote: (poll: Ref<Poll>, idea: Ref<Idea>, results: VoteEvaluationCriteria) =>
			this.mutate("commitCriteriaVote", { pollId: takeId(poll), ideaId: takeId(idea), results }, undefined),
		finish: (poll: Ref<Poll>) => this.mutate("finishVoting", { pollId: takeId(poll) }, undefined)
	};

	aiAction = {
		remove: (id: Ref<AIAction>) => this.mutate("removeAIAction", { id: takeId(id) }, undefined),

		create: (data: Partial<AIAction>) => this.mutate("createAIAction", { data }, GQL_AI_ACTION_SHAPE),

		update: (id: Ref<AIAction>, data: Partial<AIAction>) =>
			this.mutate("updateAIAction", { id: takeId(id), data }, GQL_AI_ACTION_SHAPE),

		list: (options?: QueryOptions<AIAction>) => this.query("aiActions", { options }, GQL_AI_ACTION_SHAPE),

		get: (id: Ref<AIAction> | BuiltInAIActionKind) =>
			this.query(
				"aiAction",
				{ id: isId(id) || isModel(id) ? takeId(id) : builtInAIActionKindToId(id) },
				GQL_AI_ACTION_SHAPE
			),

		detectClustersInRoom: (roomId: Ref<Room>) =>
			this.mutate("detectClustersInRoom", { roomId: takeId(roomId) }, GQL_IDEA_CLUSTER_SHAPE),

		extractTextFromMedia: (mediaId: Ref<MediaDAO>) =>
			this.mutate("extractTextFromMedia", { mediaId: takeId(mediaId) }, true),

		extractTextFromUrl: (url: string) => this.mutate("extractTextFromUrl", { url }, true),

		extractSearchResultFromQuery: (query: string) => this.mutate("extractSearchResultFromQuery", { query }, true),

		recognize: async (formData: FormData): Promise<{ text: string | undefined }> => {
			const res = await this.sendRequest({
				body: formData,
				method: "POST",
				path: "/recognize",
				headers: {
					Accept: "application/json",
					"Content-Type": null
				}
			});

			return await res.json();
		},

		run: async (
			aiActionId: Ref<AIAction> | BuiltInAIActionKind,
			context: AIActionContext,
			abortSignal?: AbortSignal
		) => {
			let result = "";

			const stream = this.aiAction.runStream(aiActionId, context, abortSignal);

			for await (const chunk of stream) {
				result += chunk;
			}

			return result;
		},

		runStream: (
			aiActionId: Ref<AIAction> | BuiltInAIActionKind,
			aiActionContext: AIActionContext,
			abortSignal?: AbortSignal
		) =>
			this.streamRequest({
				path: "/aiAction",
				method: "POST",
				body: JSON.stringify({
					aiActionId:
						isId(aiActionId) || isModel(aiActionId) ? takeId(aiActionId) : builtInAIActionKindToId(aiActionId),
					aiActionContext: {
						...aiActionContext,
						modelOptions: {
							...(aiActionContext.modelOptions ?? {}),
							// Always include "aiModel" in the context
							aiModel: workspaceStore.currentWorkspace?.data?.aiContext?.modelOptions?.aiModel
						}
					}
				}),
				abortSignal
			}),

		streamChatMessage: (
			copilot: Ref<Copilot>,
			{
				text,
				aiActionId,
				aiActionContext
			}: { text: string; aiActionId: Id<AIAction> | BuiltInAIActionKind; aiActionContext: AIActionContext },
			abortSignal?: AbortSignal
		) =>
			this.streamRequest({
				path: `/copilots/${takeId(copilot)}/chatMessages`,
				method: "POST",
				body: JSON.stringify({
					text,
					aiActionId:
						aiActionId != null ? (isId(aiActionId) ? aiActionId : builtInAIActionKindToId(aiActionId)) : undefined,
					aiActionContext: {
						...aiActionContext,
						modelOptions: {
							...(aiActionContext.modelOptions ?? {}),
							// Always include "aiModel" in the context
							aiModel: workspaceStore.currentWorkspace?.data?.aiContext?.modelOptions?.aiModel
						}
					}
				}),
				abortSignal
			})
	};

	unfurlUrl = (url: string) => this.mutate("unfurlUrl", { url }, GQL_UNFURLED_URL_SHAPE);

	isFriendOfIdeamap = () =>
		this.sendRequest({
			path: "/isFriendOfIdeamap",
			method: "GET"
		})
			.then((res) => res.json())
			.then((res) => res.isFriendOfIdeamap as boolean);

	subscribeToMarketing = () => this.mutate("subscribeToMarketing", {}, undefined);

	promotekit = {
		track: async (code: string, email?: string): Promise<string | null> => {
			const response = await fetch(`${API_BASE_URL}/promotekit/track`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					code,
					email
				})
			});

			const json = await response.json();
			return json.referral as string | null;
		}
	};

	intercom = {
		identityToken: () => this.query("intercomIdentityToken", {}, true)
	};

	wiki = {
		search: (query: string) => this.mutate("wikiSearch", { query }, true as any),
		content: (queryOrPageId: string) => this.mutate("wikiContent", { queryOrPageId }, true)
	};
}

export const api = new Api();
